<template>
  <div class="mt-4">
    <CCard class="mb-0">
      <CCardBody>
        <CRow class="mb-3">
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Štatistiky</h4>
            <div class="small text-muted">{{ displayDateRange }}</div>
          </CCol>
          <CCol sm="7" class="d-none d-md-block">
            <!-- Výber dátumu (Ant Design) -->
            <a-range-picker
              v-model="dateRange"
              format="DD.MM.YYYY"
              @change="onDateChange"
              separator="-"
              :locale="locale2"
              class="float-right"
              style="margin-right: 10px"
            />
          </CCol>
        </CRow>
        <WidgetsDropdown
  :objednavky="dash.localOrders"
  :trzby="dash.localRevenue"
  :wholesaleOrders="dash.wholesaleOrders"
  :wholesaleRevenue="dash.wholesaleRevenue"
  :priemernaHodnota="(dash.localOrders > 0 ? (dash.localRevenue / dash.localOrders).toFixed(2) : 0)"
  :konverznyPomer="(dash.sessions > 0 ? ((dash.localOrders / dash.sessions) * 100).toFixed(2) : 0)"
  :objednavkyTrend="ordersTrend.retail"
  :objednavkyWholesaleTrend="ordersTrend.retail"
  :priemernaHodnotaWholesale="(dash.wholesaleOrders > 0 ? (dash.wholesaleRevenue / dash.wholesaleOrders).toFixed(2) : 0)"
  :trzbyTrend="revenueTrend.retail"
  :trzbyWholesaleTrend="revenueTrend.retail"
  :aovTrend="aovTrend"
  :aovTrendWholesale="aovTrendWholesale"
  :konverziaTrend="konverziaTrend"
/>
</CCardBody>
</CCard>
    <!-- Hlavná karta s prevádzkou -->
    <CCard class="pt-0">
      <CCardBody>
        <!-- Graf alebo loader -->
        <div v-if="dash && dash.dailyTraffic && dash.dailyTraffic.length">
          <MainChartExample
            :chartData="dash.dailyTraffic"
            :key="chartKey"
            style="height:300px; margin-top:40px;"
          />
        </div>
        <div
          v-else
          class="d-flex justify-content-center align-items-center"
          style="height:300px;"
        >
          <a-spin tip="Načítavam údaje..." />
        </div>
      </CCardBody>

      <!-- Súhrn (Návštevy, Unikátni používatelia, Zobrazenia stránok, Noví používatelia, Miera odchodov) -->
      <CCardFooter>
        <CRow class="text-center">
          <!-- Návštevy (Sessions) -->
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Návštevy</div>
            <strong>{{ dash.sessions }}</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="success"
              :value="visitsPercent"
            />
          </CCol>

          <!-- Unikátni používatelia (Unique Users) -->
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Unikátni používatelia</div>
            <strong>{{ dash.uniqueUsers }}</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="info"
              :value="uniquePercent"
            />
          </CCol>

          <!-- Zobrazenia stránok (Pageviews) -->
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Zobrazenia stránok</div>
            <strong>{{ dash.pageViews }}</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="warning"
              :value="pageViewsPercent"
            />
          </CCol>

          <!-- Noví používatelia (New Users) -->
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Noví používatelia</div>
            <strong>{{ dash.newUsers }}</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="danger"
              :value="newUsersPercent"
            />
          </CCol>

          <!-- Miera odchodov (Bounce Rate) -->
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Miera odchodov</div>
            <strong>Priemerná miera ({{ dash.bounceRate }}%)</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              :value="bounceRatePercent"
            />
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>

    <!-- Ďalšie sekcie -->
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Správanie klientov
          </CCardHeader>
          <CCardBody>
            <CRow>
              <!-- Ľavý stĺpec (Noví / Vracajúci sa klienti) -->
              <CCol sm="12" lg="6">
                <CRow>
                  <CCol sm="6">
                    <CCallout color="info">
                      <small class="text-muted">Noví klienti</small><br />
                      <strong class="h4">{{ dash.newUsers }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="6">
                    <CCallout color="danger">
                      <small class="text-muted">Vracajúci sa klienti</small><br />
                      <strong class="h4">{{ dash.recurringClients }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
              </CCol>

              <!-- Pravý stĺpec (Zobrazenia stránok / Organické) -->
              <CCol sm="12" lg="6">
                <CRow>
                  <CCol sm="6">
                    <CCallout color="warning">
                      <small class="text-muted">Zobrazenia stránok</small><br />
                      <strong class="h4">{{ dash.pageViews }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="6">
                    <CCallout color="success">
                      <small class="text-muted">Organické</small><br />
                      <strong class="h4">{{ dash.organic }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>

                <!-- Najnavštevovanejšie stránky (ak nechcete progress bars) -->
                <ul class="horizontal-bars type-2">
                  <template v-for="(dd, i) in dash.mostvisits">
                    <div class="progress-group" :key="i + 'dsff'">
                      <div class="progress-group-header">
                        <span class="title">
                          <a
                            :href="dd.page"
                            target="_blank"
                            style="color:#000"
                          >
                            {{ dd.node.value }}
                          </a>
                        </span>
                        <span class="ml-auto font-weight-bold">
                          {{ parseInt(dd.times) }}
                        </span>
                      </div>
                      <div class="progress-group-bars">
                        <CProgress
                          class="progress-xs"
                          :value="parseInt(dd.times)"
                          color="success"
                        />
                      </div>
                    </div>
                  </template>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">

    <!-- Purchases & Most Visited Pages -->
    <CRow>
      <!-- Ľavý stĺpec: Nákupy (purchases) -->
      <CCol sm="12" lg="6">
        <CCard>
          <CCardHeader>
            <h5 class="mb-2">Predaje podľa miest</h5>
          </CCardHeader>
          <CCardBody>
        <!-- Zobraz nákupy alebo správu, ak nie sú -->
        <div v-if="dash && dash.purchases && dash.purchases.length">
          <template v-for="(dd, i) in dash.purchases" >
            <div class="progress-group mb-4" :key="'c' + i">
              <div class="progress-group-prepend">
                <span class="progress-group-text">
                  {{ dd.city }}
                </span>
              </div>
              <div class="progress-group-bars">
                <CProgress
                  class="progress-xs"
                  color="info"
                  :value="parseInt(dd.cart)"
                />
                <CProgress
                  class="progress-xs"
                  color="danger"
                  :value="parseInt(dd.checkout)"
                />
                <CProgress
                  class="progress-xs"
                  color="success"
                  :value="parseInt(dd.purchase)"
                />
              </div>
            </div>
          </template>

          <div class="legend text-center">
            <small>
              <span class="mr-2">
                <sup class="mr-1">
                  <CBadge shape="pill" color="info">&nbsp;</CBadge>
                </sup>
                Pridanie do košíka
              </span>
              <span class="mr-2">
                <sup class="mr-1">
                  <CBadge shape="pill" color="danger">&nbsp;</CBadge>
                </sup>
                Začiatok pokladne
              </span>
              <span class="">
                <sup class="mr-1">
                  <CBadge shape="pill" color="success">&nbsp;</CBadge>
                </sup>
                Nákup
              </span>
            </small>
          </div>
        </div>
        <div v-else class="alert alert-info">
          Žiadne nákupy neboli nájdené.
        </div>
      </CCardBody>
    </CCard>
      </CCol>

      <!-- Pravý stĺpec: Najnavštevovanejšie stránky -->
      <CCol sm="12" lg="6">
        <CCard>
          <CCardHeader>
            <h5 class="mb-2">Najnavštevovanejšie stránky</h5>
          </CCardHeader>
          <CCardBody>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th style="width: 40px;">#</th>
                  <th>URL</th>
                  <th style="width: 80px;">Zobrazení</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dash.mostVisited" :key="`mostvisit-${index}`">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <a :href="item.pagePath" target="_blank">
                      {{ item.pagePath }}
                    </a>
                  </td>
                  <td>{{ item.views }}</td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { DatePicker, Spin } from 'ant-design-vue'
import moment from 'moment'
    import 'moment/locale/sk';
import MainChartExample from './charts/MainChartExample'
import WidgetsDropdown from './widgets/WidgetsDropdown'
import { dataService } from '../_services'

const { RangePicker } = DatePicker
var locale2 = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Začiatok", "Koniec"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storočie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "MMMM YYYY"
    }
export default {
  name: 'Dashboard',
  components: {
    MainChartExample,
    WidgetsDropdown,
    'a-range-picker': RangePicker,
    'a-spin': Spin
  },
  data() {
    return {
      selected: 'Month',
      dash: false,     // Uchová JSON od servera
      chartKey: 0,     // Kľúč na vynútenie znovu-vykreslenia grafu
      dateRange: [moment().startOf('month'), moment().endOf('month')],
      tableItems: [],
      locale2,
      tableFields: []
    }
  },
  computed: {
    orders() {
    return this.dash.localOrders; // e.g., from your local DB stats
  },
  revenue() {
    return this.dash.localRevenue;
  },
  priemernaHodnota() {
    return this.dash.localOrders > 0
      ? (this.dash.localRevenue / this.dash.localOrders).toFixed(2)
      : 0;
  },
  priemernaHodnotaWholesale() {
    return this.dash.wholesaleOrders > 0
      ? (this.dash.wholesaleRevenue / this.dash.wholesaleOrders).toFixed(2)
      : 0;
  },
  konverznyPomer() {
    // Assuming dash.sessions from GA
    return this.dash.sessions > 0
      ? ((this.dash.localOrders / this.dash.sessions) * 100).toFixed(2)
      : 0;
  },
  // And trend arrays (which you might build from your daily data)
  ordersTrend() {
    return this.dash.ordersTrend; // from your API response
  },
  revenueTrend() {
    return this.dash.revenueTrend;
  },
  aovTrend() {
    return this.dash.aovTrend_retail;
  },
  aovTrendWholesale() {
    return this.dash.aovTrend_wholesale;
  },
  konverziaTrend() {
    return this.dash.konverziaTrend || [];
  },
    displayDateRange() {
      if (this.dateRange && this.dateRange.length === 2) {
        return this.dateRange[0].format('DD.MM.YYYY') + ' - ' + this.dateRange[1].format('DD.MM.YYYY')
      }
      return ''
    },
    // Príklady výpočtov pre progress bary
    visitsPercent() {
      if (!this.dash || !this.dash.sessions) return 0
      return Math.min(this.dash.sessions, 100)
    },
    uniquePercent() {
      if (!this.dash || !this.dash.uniqueUsers) return 0
      return Math.min(this.dash.uniqueUsers, 100)
    },
    pageViewsPercent() {
      if (!this.dash || !this.dash.pageViews) return 0
      return Math.min(this.dash.pageViews, 100)
    },
    newUsersPercent() {
      if (!this.dash || !this.dash.newUsers) return 0
      return Math.min(this.dash.newUsers, 100)
    },
    bounceRatePercent() {
      if (!this.dash || !this.dash.bounceRate) return 0
      return Math.min(this.dash.bounceRate, 100)
    }
  },
  mounted() {
    // Prvé načítanie s východzím dátumovým rozsahom
    this.fetchData()
  },
  methods: {
    onDateChange(dates) {
      this.dateRange = dates
      this.fetchData()
    },
    fetchData() {
      const start_date = this.dateRange[0].format('YYYY-MM-DD')
      const end_date = this.dateRange[1].format('YYYY-MM-DD')
      const params = { start_date, end_date }

      dataService.axiosPost('dashboarddata', params).then((results) => {
        if (results) {
          // Očakávame JSON v tvare:
          // {
          //   dailyTraffic: [...],
          //   sessions: 123,
          //   uniqueUsers: 99,
          //   pageViews: 150,
          //   newUsers: 40,
          //   recurringClients: 59,
          //   bounceRate: 5.67,
          //   purchases: [...],
          //   mostVisited: [...]
          // }
          this.dash = results.data.data
          // Prekresliť graf
          this.chartKey = new Date().getTime()
        }
      })
    },
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
</script>
