<template>
  <CChartLine
    :datasets="computedDatasets"
    :options="defaultOptions"
    :labels="computedLabels"
  />
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'

export default {
  name: 'MainChartExample',
  components: { CChartLine },
  props: {
    // Očakáva pole denných údajov z dashboardu
    chartData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    computedLabels() {
      if (this.chartData.length) {
        return this.chartData.map(entry => {
          // Vytvorí objekt Date a formátuje ako "deň mesiac" (napr. "2 mar")
          const d = new Date(entry.date)
          // Používame slovenské nastavenie pre mesiac
          return `${d.getDate()} ${d.toLocaleString('sk', { month: 'short' })}`
        })
      }
      // Fallback: slovenské skratky dní
      return ['Po', 'Ut', 'St', 'Št', 'Pi', 'So', 'Ne']
    },
    computedDatasets() {
      const brandInfo = getStyle('info') || '#20a8d8'
      const brandSuccess = getStyle('success2') || '#4dbd74'
      const brandDanger = getStyle('danger') || '#f86c6b'

      if (this.chartData.length) {
        const sessionsData = this.chartData.map(entry => entry.sessions)
        const uniqueUsersData = this.chartData.map(entry => entry.uniqueUsers)
        const pageViewsData = this.chartData.map(entry => entry.pageViews)
        return [
          {
            label: 'Návštevy',
            backgroundColor: hexToRgba(brandInfo, 10),
            borderColor: brandInfo,
            pointHoverBackgroundColor: brandInfo,
            borderWidth: 2,
            data: sessionsData
          },
          {
            label: 'Unikátni používatelia',
            backgroundColor: 'transparent',
            borderColor: brandSuccess,
            pointHoverBackgroundColor: brandSuccess,
            borderWidth: 2,
            data: uniqueUsersData
          },
          {
            label: 'Zobrazenia stránok',
            backgroundColor: 'transparent',
            borderColor: brandDanger,
            pointHoverBackgroundColor: brandDanger,
            borderWidth: 2,
            data: pageViewsData
          }
        ]
      }

      // Fallback: nulové dáta, aby graf zobrazoval rovný priebeh
      const labels = this.computedLabels
      const flatData = labels.map(() => 0)
      return [
        {
          label: 'Návštevy',
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: flatData
        },
        {
          label: 'Unikátni používatelia',
          backgroundColor: 'transparent',
          borderColor: brandSuccess,
          pointHoverBackgroundColor: brandSuccess,
          borderWidth: 2,
          data: flatData
        },
        {
          label: 'Zobrazenia stránok',
          backgroundColor: 'transparent',
          borderColor: brandDanger,
          pointHoverBackgroundColor: brandDanger,
          borderWidth: 2,
          data: flatData
        }
      ]
    },
    yAxesMax() {
      // Vypočíta maximálnu hodnotu a pridá 20% maržu
      if (this.chartData.length) {
        let maxVal = 0
        this.chartData.forEach(entry => {
          maxVal = Math.max(maxVal, entry.sessions, entry.uniqueUsers, entry.pageViews)
        })
        return Math.ceil(maxVal * 1.2)
      }
      return 250
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        legend: { display: true },
        scales: {
          xAxes: [{
            gridLines: { drawOnChartArea: false },
            ticks: {
              // Voliteľne môžete pridať otočenie alebo štýl pre popisky osi X.
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(this.yAxesMax / 5),
              max: this.yAxesMax
            },
            gridLines: { display: true }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  }
}
</script>
