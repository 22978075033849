<template>
  <CRow>
    <!-- Widget 1: Objednávky -->
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="primary" :header="objednavky.toString()" text="Objednávky">

        <template #footer>
          <CChartLineSimple
            pointed
            class="mx-3"
            style="height:75px"
            :data-points="objednavkyTrend"
            point-hover-background-color="secondary"
            label="Objednávky"
            labels="dni"
          />
        </template>
      </CWidgetDropdown>
    </CCol>

    <!-- Widget 2: Tržby -->
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="info" :header="formatPrice(trzby) + ' €'" text="Tržby">
        <template #footer>
          <CChartLineSimple
            pointed
            class="mx-3"
            style="height:75px"
            :data-points="trzbyTrend"
            point-hover-background-color="secondary"
            label="Tržby"
            labels="dni"
          />
        </template>
      </CWidgetDropdown>
    </CCol>


    <!-- Widget 3: Priemerná hodnota objednávky -->
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="warning" :header="formatPrice(priemernaHodnota) + ' €'" text="Priemerná hodnota objednávky">

        <template #footer>
          <CChartLineSimple
            class="mx-3"
            style="height:75px"
            background-color="rgba(255,255,255,.2)"
            :data-points="aovTrend"
            :options="{ elements: { line: { borderWidth: 2 }}}"
            label="Priemerná hodnota"
            labels="dni"
          />
        </template>
      </CWidgetDropdown>
    </CCol>

      <!-- Widget 1: Objednávky -->
      <CCol sm="6" lg="4">
      <CWidgetDropdown color="custom-red" :header="wholesaleOrders.toString()" text="Objednávky veľkoobchod">

        <template #footer>
          <CChartLineSimple
            pointed
            class="mx-3"
            style="height:75px"
            :data-points="objednavkyWholesaleTrend"
            label="Objednávky"
            labels="dni"
          />
        </template>
      </CWidgetDropdown>
    </CCol>

    <!-- Widget 2: Tržby -->
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="custom-blue" :header="formatPrice(wholesaleRevenue) + ' €'" text="Tržby veľkoobchod">
        <template #footer>
          <CChartLineSimple
            pointed
            class="mx-3"
            style="height:75px"
            :data-points="trzbyWholesaleTrend"
            label="Tržby"
            labels="dni"
          />
        </template>
      </CWidgetDropdown>
    </CCol>

    <!-- Widget 4: Konverzný pomer
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="danger" :header="konverznyPomer.toString()" text="Konverzný pomer">
        <template #default>
          <CDropdown color="transparent p-0" placement="bottom-end">
            <template #toggler-content>
              <CIcon name="cil-chart-line"/>
            </template>
          </CDropdown>
        </template>
        <template #footer>
          <CChartBarSimple
            class="mx-3"
            style="height:75px"
            background-color="rgb(250, 152, 152)"
            :data-points="konverziaTrend"
            label="Konverzný pomer"
            labels="dni"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
-->
 <!-- Widget 3: Priemerná hodnota objednávky -->
 <CCol sm="6" lg="4">
      <CWidgetDropdown color="dark" :header="formatPrice(priemernaHodnotaWholesale) + ' €'" text="Priemerná hodnota objednávky veľkoobchod">
         <template #footer>
          <CChartLineSimple
            class="mx-3"
            style="height:75px"
            background-color="rgba(255,255,255,.2)"
            :data-points="aovTrendWholesale"
            :options="{ elements: { line: { borderWidth: 2 }}}"
            label="Priemerná hodnota"
            labels="dni"
          />
        </template>
      </CWidgetDropdown>
    </CCol>

  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  props: {
    objednavky: {
      type: [Number, String],
      default: '0'
    },
    trzby: {
      type: [Number, String],
      default: '0'
    },

    wholesaleOrders: {
      type: [Number, String],
      default: '0'
    },

    wholesaleRevenue: {
      type: [Number, String],
      default: '0'
    },
    priemernaHodnota: {
      type: [Number, String],
      default: '0'
    },
    priemernaHodnotaWholesale: {
      type: [Number, String],
      default: '0'
    },
    konverznyPomer: {
      type: [Number, String],
      default: '0'
    },
    objednavkyTrend: {
      type: Array,
      default: () => []
    },
    objednavkyWholesaleTrend: {
      type: Array,
      default: () => []
    },
    trzbyTrend: {
      type: Array,
      default: () => []
    },
    trzbyWholesaleTrend: {
      type: Array,
      default: () => []
    },
    aovTrend: {
      type: Array,
      default: () => []
    },
    aovTrendWholesale: {
      type: Array,
      default: () => []
    },
    konverziaTrend: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatPrice(inputPrice) {
  return Number(inputPrice).toLocaleString('sk-SK', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

  }
}
</script>
